/* eslint-disable */
import Footer from "../../Includes/Footer";
import Sidebar from "../../Includes/Sidebar";
import { Link } from "react-router-dom";
import {useState } from "react";
import { DataNoteDetails, DataNotes } from "../Data/DataNotes";

const swal = require("sweetalert2");

export default function NotesDetails() {
  const [refreshData, setRefreshData] = useState(false); // Ajouter un état pour rafraîchir DataNotes
 
  return (
    <>
      <Sidebar />
      <div className="content-wrapper">
        <section className="content-header mt-3">
          <div className="container-fluid">
            <div className="row mb-2 mt-3">
              <div className="col-sm-6">
                <h1 className="display">Details des Notes</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <Link to="/dashboard" className="breadcrumb-item">
                    Tableau de bord
                  </Link>
                  <Link to="/courses" className="breadcrumb-item">
                    Matieres
                  </Link>
                  <Link to="/notes" className="breadcrumb-item ">Notes</Link>
                  <li className="breadcrumb-item active">Details</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="card">
                <DataNotes />{" "}
                {/* Passez refreshData comme prop */}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
