import { useContext, useEffect, useState } from "react";
import Sidebar from "../../Includes/Sidebar";
import Footer from "../../Includes/Footer";
import { CourseContext } from "../../context/CourseContext";
import AuthContext from "../../context/AuthContext";
import { useLocation } from "react-router-dom";

function NoteDetails() {
    const { GetNoteById, updateNote, getCourses } = useContext(CourseContext);
    const { user } = useContext(AuthContext);
    const [records, setRecords] = useState();
    const location = useLocation();
    const { course_id } = location.state || {};
    const [loading, setLoading] = useState(false);
    const [classes, setClasses] = useState([]);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchNoteDetails = async () => {
        try {
          const data = await GetNoteById(course_id);
          setRecords(data);
        } catch (error) {
          console.error("Erreur lors de la récupération des détails :", error);
        }
      };
      fetchNoteDetails();
    }, [course_id, GetNoteById]);
  
    useEffect(() => {
      const fetchCourses = async () => {
        try {
          const data = await getCourses();
          setClasses(data);
        } catch (error) {
          console.error("Erreur lors de la récupération des cours :", error);
        }
      };
      fetchCourses();
    }, [getCourses]);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setRecords({ ...records, [name]: value });
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      formData.append("created_by", user.user_id);
  
      setLoading(true);
      setError(null);
      try {
        await updateNote(records.id, formData);
        alert("Note mise à jour avec succès !");
      } catch (error) {
        setError("Une erreur s'est produite lors de la mise à jour.");
        console.error("Erreur :", error);
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <>
        <Sidebar />
        <div className="content-wrapper wow fadeIn" data-wow-delay="0.05s">
          <section className="content-header mt-3">
            {/* Header */}
          </section>
          <div className="container-fluid">
            <div className="card p-3">
              {/* Error message */}
              {error && <div className="alert alert-danger">{error}</div>}
              <form
                onSubmit={handleSubmit}
                className="forms-sample"
                encType="multipart/form-data"
              >
                <div className="row">
                  {/* Champs de formulaire */}
                  {/* Nom */}
                  <div className="col-md-6">
                    <input
                      type="text"
                      value={`${records?.nom || ""} ${records?.prenom || ""}`}
                      readOnly
                      className="form-control"
                    />
                  </div>
                  {/* Autres champs */}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                  disabled={loading}
                >
                  {loading ? "Chargement..." : "Enregistrer"}
                </button>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
  
  export default NoteDetails;
  