/*eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../context/CourseContext";
import { DataNotesDetailsToken } from "../Data/DataNotes";
import Loader from "../../Includes/loader/loader";
import { AdminContext } from "../../context/AdminContext";
import AuthContext from "../../context/AuthContext";
import { Url1 } from "../../context/BaseUrl";
import jsPDF from "jspdf";
import 'jspdf-autotable';
const swal = require('sweetalert2')

const Url = Url1;

function StudentResult() {
  const { getStudentTotalNotesByToken, GetStudentBulletins, getBulletins, } = useContext(CourseContext);
  const [records, setRecords] = useState({});
  const [loading, setLoading] = useState(true);
  const { getAnneeAcStudent } = useContext(AdminContext);
  const { logoutUser } = useContext(AuthContext);
  const [downloading, setDownloading] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const notesData = await getStudentTotalNotesByToken();
  //       const anneeAcData = await getAnneeAcStudent();
  //       setRecords((prevRecords) => ({
  //         ...notesData,
  //         anneeAc: anneeAcData,
  //       }));
  //       await GetStudentBulletins(record.matricule);
  //     } catch (error) {
  //       console.error(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, [getStudentTotalNotesByToken, getAnneeAcStudent]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const notesData = await getStudentTotalNotesByToken();
        const anneeAcData = await getAnneeAcStudent();
        setRecords((prevRecords) => ({
          ...notesData,
          anneeAc: anneeAcData,
        }));
        await GetStudentBulletins(records.matricule); // assure-toi que 'records' est bien initialisé
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    if (Object.keys(records).length === 0) { // Lancer fetchData uniquement si 'records' est vide
      fetchData();
    }
  }, [getStudentTotalNotesByToken, getAnneeAcStudent]); // Dépendances non changées fréquemment
  
  const record = records || {};

  // Fonction pour déterminer la classe de la couleur en fonction de la moyenne
  const getAverageClass = (average) => {

    return average < 5 ? "text-danger font-weight-bold" : "font-weight-bold";
  };

  const generateStudentPDF = async (studentData,date) => {
      if (!studentData || !Array.isArray(studentData.notes)) {
    console.error("Données invalides ou non disponibles pour générer le PDF.");
    return;
  }

    const doc = new jsPDF('p', 'pt', 'letter'); // 'letter' sets the size to 8.5 x 11 inches
  
    // Header
    doc.addImage('/img/header-1.png', 'PNG', 0, 0, 612, 130);
  
    // Student Info
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.text(`Nom : ${studentData.nom}`, 40, 150);
    doc.text(`Prenom : ${studentData.prenom}`, 40, 170);
    doc.text(`Année Académique : ${date}`, 200, 195);
    doc.text(`Matricule : ${studentData.matricule}`, 350, 150);
    doc.text(`Classe : ${studentData.niveau} ${studentData.classe_nom}`, 350, 170);
  
    // Notes Table
    const tableColumn = ["Matières", "Max", "Controle 1", "Controle 2", "Controle 3"];
    const tableRows = (studentData.notes || []).map(note => [
      note.course_name,
      note.ponderation,
      note.notes["1er trimestre"],
      note.notes["2e trimestre"],
      note.notes["3e trimestre"]
    ]);
    
  
    // Adding a smaller, gray-filled line before the totals and averages
    const customRow = [{ content: "", colSpan: 5, styles: { fillColor: [200, 200, 200], fontSize: 5, lineWidth: 0 } }];
    tableRows.push(customRow);
  
 // Adding totals and averages to the table
 tableRows.push([
  { content: "Total", styles: { halign: 'right', fontStyle: 'bold', cellPadding: { right: 5 } } },
  { content: studentData.total_pond_1er_trimestre, styles: { fontStyle: 'bold' } },
  { content: studentData.total_notes_1er_trimestre, styles: { fontStyle: 'bold' } },
  { content: studentData.total_notes_2e_trimestre, styles: { fontStyle: 'bold' } },
  { content: studentData.total_notes_3e_trimestre, styles: { fontStyle: 'bold' } }
]);

tableRows.push([
  { content: "Moyenne", styles: { halign: 'right', fontStyle: 'bold', cellPadding: { right: 5 } } },
  { content: "10", styles: { fontStyle: 'bold' } },
  { content: studentData.average_1er_trimestre, styles: { fontStyle: 'bold' } },
  { content: studentData.average_2e_trimestre, styles: { fontStyle: 'bold' } },
  { content: studentData.average_3e_trimestre, styles: { fontStyle: 'bold' } }
]);

    doc.autoTable({
      startY: 200,
      head: [tableColumn],
      body: tableRows,
      theme: 'grid',
      styles: { 
        font: 'times', 
        fontSize: 12, // Reduce the font size
        cellPadding: 2 // Adjust cell padding if necessary
      },
      headStyles: { halign:'center', valign: 'middle',fillColor: [1, 80, 133], textColor: [255, 255, 255] },
      bodyStyles: { 
        valign: 'middle', 
        textColor: [22, 22, 22],
        halign: 'center' // Center align text in all body cells
      },
      columnStyles: {
        0: { halign: 'left' , fontStyle: 'bold'}, // Align the first column to the left
        1: { halign: 'center' }, // Align the second column to the center
        2: { halign: 'center' }, // Align the third column to the center
        3: { halign: 'center' }, // Align the fourth column to the center
        4: { halign: 'center' }  // Align the fifth column to the center
      },
      margin: { right: 220 }
    });
  
    const finalY = doc.lastAutoTable.finalY;
  
    // Interval Table
    const intervalTableColumn = ["Intervalle", "Niveau", "Appreciations"];
    const intervalTableRows = [
      ["100-90", "5", "T-D"],
      ["89-70", "4", "D"],
      ["69-70", "3", "P."],
      ["54-30", "2", "P-D"],
      ["Moins de 30", "1", "TPD"]
    ];
  
    doc.autoTable({
      startY: 200,
      head: [intervalTableColumn],
      body: intervalTableRows,
      theme: 'grid',
      styles: { 
        font: 'times', 
        fontSize: 10, // Reduce the font size
        cellPadding: 1 // Adjust cell padding if necessary
      },
      headStyles: { fillColor: [1, 80, 133], fontStyle: 'bold', textColor: [255, 255, 255] },
      bodyStyles: { textColor: [22, 22, 22] }, // Center align text
      margin: { left: 395 }
    });
  
    const finalY2 = Math.max(doc.lastAutoTable.finalY, finalY);
  
    // General Average Table
    const MoyenneGTableColumn = ["Moyenne Generale", ""];
    const MoyenneGTableRows = [
      ["1er Controle", studentData.average_1er_trimestre],
      ["2e Controle", studentData.average_2e_trimestre],
      ["3e Controle", studentData.average_3e_trimestre],
      ["Moyenne de l'année", studentData.moyenne_generale],
      ["Décision de l'année", studentData.decision_annee],
    ];
  
    doc.autoTable({
      startY: 287,
      head: [MoyenneGTableColumn],
      body: MoyenneGTableRows,
      theme: 'grid',
      styles: { 
        font: 'times', 
        fontSize: 10, // Reduce the font size
        cellPadding: 1 // Adjust cell padding if necessary
      },
      headStyles: { halign:'center', valign: 'middle', fontStyle: 'bold',
        fillColor: [1, 80, 133], textColor: [255, 255, 255] },
      bodyStyles: { textColor: [22, 22, 22] }, // Center align text
      margin: { left: 395 }
    });
  
    const finalY4 = Math.max(doc.lastAutoTable.finalY, finalY);
  
    // Decision and Notes
    const notesTableColumn1 = ["Valeurs des Notes"];
  
    doc.autoTable({
      startY: 373,
      head: [notesTableColumn1],
      theme: 'grid',
      styles: { 
        font: 'times', 
        fontSize: 10, // Reduce the font size
        cellPadding: 1 // Adjust cell padding if necessary
      },
      headStyles: {halign:'center', valign: 'middle', fontStyle: 'bold',
        fillColor: [1, 80, 133], textColor: [255, 255, 255] },
      margin: { left: 395 }
    });
  
    const notesTableRows = [
      ["T-D", "Très Developper", "D", "Developper"],
      ["P.", "Passable", "P-D", "Peu Developper"],
      ["TPD", "Très Peu Developper", "", ""]
    ];
  
    doc.autoTable({
      startY: 386,
      body: notesTableRows,
      theme: 'grid',
      styles: { 
        font: 'times', 
        fontSize: 10, // Reduce the font size
        cellPadding: 1 // Adjust cell padding if necessary
      },
      bodyStyles: { textColor: [22, 22, 22] }, // Center align text
      margin: { left: 395 }
    });
  
    const finalY3 = doc.lastAutoTable.finalY;
  
 
  // Adding the header "Décision de l'année"
  doc.autoTable({
    startY: 453, // Positioning the table below the last section
    head: [['Décision de l\'année']],
    body: [
      ['Promu (e)', ''],
      ['Promu (e) Ailleurs', ''],
      ['Doublé Ailleurs', '']
    ],
    theme: 'grid',
    styles: { 
      font: 'times', 
      fontSize: 10, // Adjust font size
      cellPadding: 1 // Adjust cell padding
    },
    headStyles: { halign:'center', valign: 'middle',
      fillColor: [1, 80, 133], 
      textColor: [255, 255, 255]
    },
    bodyStyles: { 
      textColor: [22, 22, 22]
    },
    margin: { left: 395 }
  });

  // Drawing the checkboxes
  const checkBoxPositions = [
    { x: 540, y: 468 },
    { x: 540, y: 481 },
    { x: 540, y: 495 }
  ];

  checkBoxPositions.forEach(pos => {
    doc.rect(pos.x, pos.y, 10, 10); // Draw checkbox
  });


    doc.save(`${studentData.nom}_${studentData.prenom}_bulletin.pdf`);
    swal.fire({
      title: "Le bulletin a été téléchargé avec succès !",
      icon: "success",
      toast: true,
      timer: 3000,
      position: 'top-right',
      timerProgressBar: true,
      showConfirmButton: false,
    });
  }
  const handleDownload = async () => {
    setDownloading(true); // Activer le chargement
    try {
      await GetStudentBulletins(record.matricule);
      
     
      generateStudentPDF(getBulletins, record.anneeAc[0].date);
    } catch (error) {
      console.error(error);
      swal.fire("Erreur", "Une erreur s'est produite lors du téléchargement.", "error");
    } finally {
      setDownloading(false); // Désactiver le chargement
    }
  };
  return (
    <>
      <nav className="navbar-light pl-3 pb-0 pr-3" style={{ display: "flex" }}>
        <ul className="navbar-nav">
          <li className="nav-item pt-2">
            <a className="nav-link" href="#" onClick={logoutUser} role="button">
              <img
                src="./img/log.png"
                className="me-2"
                alt="School Logo"
                style={{ width: "50px", height: "auto" }}
              />
              <span className="display-0 text-primary mt-3">LNFCL</span>
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto p-0 m-0">
          <li className="nav-item">
            <h4 className="nav-link position-relative">
              <span className="start-0 translate-middle-y">
                <span className="fs-6" style={{ marginLeft: "-0.3rem" }}>
                  Année Académique
                </span>
                <br />
              </span>
              {record.anneeAc ? record.anneeAc[0].date : "Chargement..."}
              <button
                onClick={logoutUser}
                className="btn btn-danger btn-sm float-end mb-1 mt-0 ml-3"
              >
                <i className="fas fa-sign-out-alt"></i>{" "}
              </button>
            </h4>
          </li>
        </ul>
      </nav>
      <div className="content">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 mt-0 pt-0 ">
              <div className="col-sm-12 pt-0 mt-0"></div>
              <div className="col-sm-6 d-flex align-items-center text-end">
                <div className="col-sm-6 bg-success rounded-lg pt-0 pb-1 text-center display-0">
                  <i className="fas fa-list"></i> Gestion des Résultats
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="content wow fadeIn" data-wow-delay="0.05s">
          <div className="container-fluid">
            <div className="row pt-2">
              <div className="col-md-3">
                <div className="card card-primary card-outline">
                  <div className="card-header p-1 text-center fw-bold bg-primary">
                    Votre Profil
                  </div>
                  {loading ? (
                    <Loader />
                  ) : (
                    <div className="card-body box-profile">
                      <div className="text-center">
                        <img
                          className="profile-user-img img-fluid img-circle"
                          src={`${Url}${record.photo}` || ""}
                          alt="User profile"
                        />
                      </div>
                      <h3 className="profile-username text-center">
                        {record.nom + " " + record.prenom || ""}
                      </h3>
                      <p className="text-center mb-0 p-0">Matricule</p>
                      <p className="text-muted text-center m-0 p-0">
                        {record.matricule || ""}
                      </p>
                      <p className="text-center mb-0">Classe</p>
                      <p className="text-muted text-center ">
                        {record.niveau + " " + record.classe || ""}
                      </p>
                      <ul className="list-group list-group-unbordered mb-3">
                        Moyenne
                        <li className="list-group-item">
                          <b>1er Trimestre</b>{" "}
                          <a
                            className={`float-right ${getAverageClass(
                              record.average_notes?.["1er trimestre"] || 0
                            )}`}
                          >
                            {record.average_notes?.["1er trimestre"] || "N/A"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>2e Trimestre</b>{" "}
                          <a
                            className={`float-right ${getAverageClass(
                              record.average_notes?.["2e trimestre"] || 0
                            )}`}
                          >
                            {record.average_notes?.["2e trimestre"] || "N/A"}
                          </a>
                        </li>
                        <li className="list-group-item">
                          <b>3e Trimestre</b>{" "}
                          <a
                            className={`float-right ${getAverageClass(
                              record.average_notes?.["3e trimestre"] || 0
                            )}`}
                          >
                            {record.average_notes?.["3e trimestre"] || "N/A"}
                          </a>
                        </li>
                      </ul>
                      {/* <a href="" className="btn btn-primary btn-block">
                        <b>Moyenne générale:</b>{" "}
                        <a
                          className={`float-right ${getAverageClass(
                            record.general_average || 0
                          )} text-white`}
                        >
                          {record.general_average || "N/A"}
                        </a>
                      </a> */}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-9">
                <div className="card">
                  <div className="card-header p-1 text-center fw-bold bg-info mb-0">
                    <div className="row">
                      <div className="col-md-12 ">
                      <button className="btn btn-primary btn-sm float-start">
                          <i className="fas fa-list"></i> Notes
                        </button>
                        <button
  className="btn btn-warning btn-sm float-end"
  onClick={handleDownload}
  disabled={downloading} // Désactiver le bouton pendant le téléchargement
>
  {downloading ? (
    <>
      <i className="fas fa-spinner fa-spin"></i> Téléchargement en cours...
    </>
  ) : (
    <>
      <i className="fas fa-print"></i> Télécharger
    </>
  )}
</button>


                      </div>
                    </div>
                  </div>
                  <div className="card-body m-0 p-0">
                    <DataNotesDetailsToken />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default StudentResult;
