import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Importer useHistory pour les anciennes versions
import { CourseContext } from "../../context/CourseContext";
const swal = require("sweetalert2");

function LoginResult() {
  const [loading, setLoading] = useState(false); // État de chargement
  const { getStudentToken } = useContext(CourseContext);
  const [matricule, setMatricule] = useState("");
  const navigate = useNavigate(); // Utilisez useNavigate pour la navigation

  const handleSearch = async (e) => {
    e.preventDefault();
    if (matricule.length > 0) {
      setLoading(true);
      try {
        const tokenData = await getStudentToken(matricule);
        if (tokenData && typeof tokenData.token === "string") {
          swal.fire({
            title: "Succès!",
            icon: "success",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          navigate('/student-result');
        } else {
          swal.fire({
            title: "Échec de connexion.\n Verifier votre matricule ou Contactez l'administrateur.",
            icon: "error",
            toast: true,
            timer: 3000,
            position: "top-right",
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        swal.fire({
          title: "... " + error.message,
          icon: "error",
          toast: true,
          timer: 3000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="container-fluid h-100">
        <div className="row flex-row h-100">
          <div className="col-xl-8 p-0 d-none d-md-block">
            <div
              className="lavalite-bg"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 100, 0.5), rgba(0, 0, 100, 0.5)), url(./img/auth/login-bg.png)`,
                height: "100vh",
                backgroundSize: "cover",
              }}
            >
              <div className="lavalite-overlay"></div>
            </div>
          </div>
          <div className="col-xl-4 my-auto">
            <div className="card">
              <Link
                to="/"
                className="close bg-white mt-0 mb-3"
                style={{ display: "flex" }}
              >
                <i className="fa fa-home text-primary"></i>
              </Link>
            </div>
            <div className="container-fluid mx-auto p-5">
              <div className="d-flex justify-content-center align-items-center mb-4">
                <a href="/">
                  <img src="/img/log.png" alt="logo" />
                </a>
              </div>
              <h5 className="text-center fw-bold">
                Lycée National François Cappoix de Limonade
              </h5>
              <form id="formid" onSubmit={handleSearch}>
                <div className="card-header bg-primary text-center text-white rounded-top py-2">
                  <h6 className="text-center mt-3">Gestion des résultats</h6>
                </div>
                <div className="form-group position-relative">
                  <input
                    type="text"
                    className="form-control pl-5"
                    value={matricule}
                    onChange={(e) => setMatricule(e.target.value)}
                    placeholder="Entrer votre matricule"
                    required
                  />
                  <i
                    className="fa fa-lock position-absolute"
                    style={{
                      top: "50%",
                      transform: "translateY(-50%)",
                      left: "10px",
                    }}
                  ></i>
                </div>
                <div className="row">
                  <div className="col text-right">
                    {/* <a href="forgot-password.html">Matricule oublié ?</a> */}
                  </div>
                </div>
                <div className="sign-btn text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <i className="fas fa-spinner fa-spin"></i> Connexion en
                        cours...
                      </>
                    ) : (
                      <>
                        <i className="fas fa-save"></i> Se Connecter
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginResult;
